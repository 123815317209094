/* eslint-disable */
import Amplify, * as AmplifyModules from "aws-amplify"
import { VueConstructor } from "vue"
import { AmplifyPlugin, AmplifyEventBus } from "aws-amplify-vue"
import aws from "../aws-exports"

// TODO:remove for production
Amplify.configure(aws)

export default ({ Vue }: { Vue: VueConstructor }) => {
  Vue.use(AmplifyPlugin, AmplifyModules)
  Vue.prototype.$Amplify = Amplify
  Vue.prototype.$AmplifyEventBus = AmplifyEventBus
  Vue.prototype.$Auth = AmplifyModules.Auth

  // TODO:move to separete boot file (e.g. common)
  Vue.prototype.$log = console.log
}

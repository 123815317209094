





import { Mixins, Component } from 'vue-property-decorator'
import PermissionsMixin from 'src/mixins/permissions-mixin'

@Component
export default class App extends Mixins(PermissionsMixin) {}

import { RouteConfig } from 'vue-router'

const routes: RouteConfig[] = [
  {
    path: '/',
    component: () => import('layouts/main-layout.vue'),
    meta: { requiresAuth: true, mainLayout: true },
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('pages/home-page.vue')
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('pages/users-page.vue')
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('pages/dashboard-page.vue')
      },
      {
        path: 'assets',
        name: 'assets',
        component: () => import('pages/assets-page.vue')
      },
      {
        path: 'asset',
        name: 'asset',
        component: () => import('pages/asset-page.vue'),
        props: route => ({ assetId: route.query.id })
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('pages/settings-page.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('layouts/simple-layout.vue'),
    children: [
      {
        path: 'sign-in',
        name: 'signIn',
        component: () => import('pages/auth-page.vue'),
        children: [
          {
            path: '/change-pass',
            name: 'changePass',
            component: () => import('pages/change-pass-page.vue'),
            props: true
          }
        ]
      }
    ]
  },
  {
    path: '*',
    component: () => import('pages/error-page.vue')
  }
]

export default routes

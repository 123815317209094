import { GET_SEARCH_ITEM } from 'src/gql/client/queries'

const resolvers = {
  Mutation: {
    updateSearchItem(_: any, { searchItem }: any, { cache }: any) {
      const data = cache.readQuery({ query: GET_SEARCH_ITEM })
      data.searchItem = searchItem
      cache.writeQuery({ query: GET_SEARCH_ITEM, data })
    }
  }
}

export default resolvers

import typeDefs from 'src/gql/client/types'
import resolvers from 'src/gql/client/resolvers'
import cache from 'src/gql/client/cache'
import AWSAppSyncClient from 'aws-appsync'
import { ApolloClient } from 'apollo-client'
import aws from '../../aws-exports'
import Vue from 'vue'

const config: any = {
  // @ts-ignore
  url: aws.aws_appsync_graphqlEndpoint,
  // @ts-ignore
  region: aws.aws_appsync_region,
  auth: {
    // @ts-ignore
    type: aws.aws_appsync_authenticationType,
    jwtToken: async () =>
      (await Vue.prototype.$Auth.currentSession()).getIdToken().getJwtToken()
  },
  offlineConfig: {
    keyPrefix: 'private'
  }
}

const options: any = {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    },
    connectToDevTools: true, // Remove this for production use
    disableOffline: true
  }
}

const awsClient: any = new AWSAppSyncClient(config, options)

const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: 'cache-and-network'
  }
}

const apolloClient = new ApolloClient({
  cache,
  resolvers,
  typeDefs,
  defaultOptions,
  link: awsClient.link,
  connectToDevTools: true
})

export default apolloClient

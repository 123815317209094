import { VueConstructor } from 'vue'
import VueApollo from 'vue-apollo'
import users from '../gql/server/usersApollo'
import metrics from '../gql/server/metricsApollo'

const appsyncProvider = new VueApollo({
  clients: {
    users,
    metrics
  },
  defaultClient: metrics
})

export default async ({ app, Vue }: { app: any; Vue: VueConstructor }) => {
  Vue.use(VueApollo)
  app.apolloProvider = appsyncProvider
}
